@font-face {
    font-family: "ARCO";
    src: url(assets/fonts/ARCO.ttf);
}

@font-face {
    font-family: "ARCO OSX";
    src: url(assets/fonts/ARCO\ for\ OSX.otf);
}

@font-face {
    font-family: "Explorer";
    src: url(assets/fonts/Explorer\ Free.otf);
}


@font-face {
    font-family: "MohrRounded-SemiBold";
    src: url(assets/fonts/MohrRoundedAlt-SemiBold.ttf);
}

@font-face {
    font-family: "MohrRounded-Bold";
    src: url(assets/fonts/MohrRoundedAlt-Bold.ttf);
}

@font-face {
    font-family: "MohrRounded-Heavy";
    src: url(assets/fonts/MohrRoundedAlt-Black.ttf);
}

@font-face {
    font-family: "MohrRounded-Medium";
    src: url(assets/fonts/MohrRoundedAlt-Medium.ttf);
}

.font-morh-m {
    font-family: MohrRounded-Medium;
}

.font-morh-b {
    font-family: MohrRounded-Bold;
}

.font-morh-h {
    font-family: MohrRounded-Heavy;
}

.font-arco {
    font-family: ARCO;
}

.font-morh-sb {
    font-family: MohrRounded-SemiBold;
}

.font-explorer {
    font-family: Explorer;
}